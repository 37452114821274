@import "../../../../App";

.unidades-container {
  @include main-section;
  height: resize(2106);
  @include is_moblie {
    height: resize(1153);
  }

  h2 {
    @include title-section;
    margin: 0 0 10px;
  }

}