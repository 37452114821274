@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap');


:root {
    /*27px*/
    font-size: calc(0.0140625 * 100vw);
    --actual-max-width: 1920;
    --actual-max-widthpx: 1920px;
    --actual-max-height: 937;
    --actual-max-heightpx: 937px;

    --light-blue: #8ECAE6;
    --green-water: rgb(33, 158, 188);
    --dark-blue: #0E3668;
    --yellow: #FFB706;
    --orange: #F28E00;

    --bg-primary: #FFFFFF;
    --bg-secondary: var(--dark-blue);
    --bg-third: var(--green-water);

    --color-font-primary: var(--dark-blue);
    --color-font-secondary: var(--orange);
}

@media only screen and (max-width: 760px) {
    :root {
        --actual-max-width: 375;
        --actual-max-widthpx: 375px;

        --actual-max-height: 751;
        --actual-max-heightpx: 751px;
        /*25px*/
        font-size: calc(0.066666666666667 * 100vw);
    }
}

@media only screen and (min-width: 1920px) {
    :root {
        font-size: 27px;
    }
}


body {
    max-width: 1920px;
    margin: auto;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg-primary);
    color: #0F3768;
}

