@import "src/App";


.casas-container {
  @include main-section;
  height: resize(727);
  @include is_moblie {
    height: resize(576);
  }

  .div-imgs-casas {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;


  }

  .swiper-container {
    width: 100%;
    height: 160%;
    @include is_moblie {
      height: auto;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination-bullet-active {
    background-color: #F18E00;
  }

  img {
    height: resize(186.24);
    @include is_moblie {
      height: resize(186.24);
    }
  }

  h2 {
    @include title-section;
    margin: 0;

    span {
      margin-left: 10px;
      color: #F18E00;
      font-size: 1.6rem;
    }

    @include is_moblie {
      span {
        margin-left: 0;
        font-size: 0.68rem;

      }
      margin-bottom: 20px;
    }
  }

  .details {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include is_moblie {
      justify-content: center;
    }

    > p {
      margin-top: 0;
      font-weight: 600;
      font-size: 1rem;
      @include is_moblie {
        font-size: 0.56rem;
      }

    }


  }


}

