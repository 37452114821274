@import "src/App";


.mission-container {
  @include main-section;
  padding-top: 1%;
  height: resize(2215);

  @include is_moblie {
    padding-top: 0;
    height: resize(1325);
  }


  > h2:first-child {
    @include title-section;
    justify-self: flex-start;
    margin: 1vw 0 0;
    @include is_moblie {
      margin: 0;
    }

  }


  .imgs-container {
    height: auto;
    display: grid;
    place-items: center;
    width: 80%;
    margin: 0 12% 0 12%;
    @include is_moblie {
      margin: 20px 12% 0 12%;
    }


    @include is_moblie {
      margin: 0 12% 0 12%;
    }


    .mission-card {
      position: relative;
      @include is_moblie {
        position: relative;
        display: grid;
        margin-bottom: 30px;

      }
      width: auto;


      img {
        object-fit: scale-down;
        height: resize(434.42);
        @include is_moblie {
          height: resize(217.9);
        }
      }

      @include is_moblie {
        &:nth-child(1) {
          > .card-text-img {
            width: resize(293.09);
          }
        }

        &:nth-child(2) {
          > .card-text-img {
            width: resize(218.25);
          }
        }

        &:nth-child(3) {
          > .card-text-img {
            width: resize(267.92);
          }
        }

        &:nth-child(4) {
          > .card-text-img {
            width: resize(258.13);
          }
        }
      }

      .card-text-img {
        position: absolute;
        display: grid;
        background-color: var(--green-water);
        place-items: center;
        z-index: 10;
        bottom: 10%;
        right: 10%;
        padding: 15px 20px;
        height: auto;
        width: fit-content;

        @include is_moblie {
          position: relative;
          padding: resize(11.9253) resize(16.512);
        }


        p {
          color: white;
          margin: 0;
          font-family: Poppins, serif;
          text-align: justify;
          font-weight: 600;
          font-size: 0.76629rem;
          @include is_moblie {
            font-size: 0.48rem;
            text-align: start;
          }
        }

      }

    }
  }

}

