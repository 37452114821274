@import "src/App";

.home-page {
  max-width: 1920px;


  .line-draw {
    max-width: 1920px;
    width: 100%;
    object-fit: scale-down;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    @include is_moblie {
      //width: unset;
      object-fit: cover;
      width: resize(375);
    }
  }

  .wave {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 10px;
    width: 100%;
    background-color: #f1f1f1;
    overflow: hidden;
    line-height: 0;
  }

  .wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
    @include is_moblie {
      height: 60px;
    }
    transform: rotateY(180deg);
  }

  .wave .shape-fill {
    fill: #fabd6b;
  }


  .wave-2 {
    position: relative;
    //top: 0;
    //left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(0deg);
  }

  .wave-2 svg {
    position: relative;
    display: block;
    width: calc(135% + 1.3px);
    height: 100px;
    @include is_moblie {
      height: 60px;
    }
    transform: rotateY(180deg);
  }

  .wave-2 .shape-fill {
    fill: #fabd6b;
  }


}