@import "src/App";

.gallery-container {
  height: 93vh;
  margin-top: 7vh;
  display: grid;
  grid-template-columns: 1fr 3fr;
  background-color: #f4f4f4;

  @include is_moblie {
    height: auto;
    margin-top: 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }


  .section-select {

    margin: 1.4rem 3rem;

    border-radius: 8px;
    padding: 40px 20px 20px 20px;

    display: flex;
    flex-direction: column;
    align-content: center;

    background-color: var(--light-blue);

    @include is_moblie {
      margin: 0;
      width: 100vw;
      padding: 0 0 20px 0;
      border-radius: 0;
    }

    .header {
      margin: 15px 20px 10px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img{
        height: 100%;
      }
      i{
        color: white;
      }
    }

    .horizontal-scroll {
      @include is_moblie {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
          display: none;
        }
      }
    }

    button {
      border-color: transparent;
      border-radius: 12px;
      height: 40px;
      cursor: pointer;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 15px;

      font-family: Poppins, sans-serif;
      font-weight: 500;
      font-size: 0.65rem;
      color: white;
      transition: .5s ease-in-out;

      &:not(:last-child) {
        margin-bottom: 25px;
        @include is_moblie {
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      &:first-child {
        @include is_moblie {

          margin-left: 20px;
        }
      }

      &:last-child {
        @include is_moblie {
          margin-right: 20px;
        }
      }


      background-color: var(--green-water);

      &.active {
        background-color: var(--dark-blue);
      }

      @include is_moblie {
        font-size: 0.45rem;
        width: fit-content;
        padding-right: 15px;
        white-space: nowrap;
      }

    }


  }

  .group-container {
    background-color: white;
    margin: 1.4rem 2rem 1.4rem 0;

    border-radius: 16px;

    @include is_moblie {
      width: 100vw;
      margin: 0;
      border-radius: 0;
    }

    .group-select {
      background-color: var(--yellow);
      border-radius: 16px;
      height: 6.5vh;
      display: flex;
      align-items: center;
      padding: 0 20px;

      @include is_moblie {
        position: sticky;
        z-index: 100;
        top: 0;

        //width: 90vw;
        background: rgba(0, 0, 0, 0.01);
        box-shadow: inset 0 22px 28px 3px rgba(0, 0, 0, 0.30);
        border-radius: 0;
      }

      button {
        border-color: transparent;
        border-radius: 10px;
        height: 4.5vh;
        cursor: pointer;


        display: flex;
        justify-content: flex-start;
        align-items: center;
        //padding-left: 15px;
        padding: 0 30px;

        font-family: Poppins, sans-serif;
        font-weight: 500;
        font-size: 0.7rem;
        transition: .5s ease-in-out;



        &:not(:last-child) {
          margin-right: 20px;
        }


        background-color: var(--orange);
        color: white;

        &.active {
          background-color: white;
          color: var(--orange);
          @include is_moblie {
            background-color: transparent;
            color: white;
            span{
              border-bottom: 3px solid var(--orange);
            }
          }
        }

        @include is_moblie {
          font-weight: 600;
          background-color: transparent;
          color: white;
        }
      }


    }

    .photos-container {
      //height: 86vh;
      height: 81vh;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include is_moblie{
        height:auto;
        margin-top: -6.5vh;
      }

      .icon-container {
        background-color: #FFF6DF;
        height: 60px;
        width: 40px;
        display: grid;
        place-items: center;
        border-radius: 9px;
        cursor: pointer;
        transition: .5s ease-in-out;
        opacity: 1;


        @include is_moblie {
          display: none;
        }

        &.not-show {
          opacity: 0;
          pointer-events: none;
        }


        &:first-child {
          margin-left: 10px;
        }

        &:last-child {
          margin-right: 10px;
        }

        i {
          color: var(--orange);
          font-size: 1rem;
        }

      }

      .photo-grid {
        display: grid;
        height: 95%;
        width: 100%;
        place-items: center;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 5px;

        grid-template-rows: 1fr 1fr 1fr;

        @include is_moblie {
          height: 100%;
          width: 100vw;
          //min-height: 10vh;
          grid-template-rows: auto;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 1px;
          grid-row-gap: 1px;
        }

        .img-container {
          height: 100%;
          width: 100%;
          display: grid;
          place-items: center;

          cursor: pointer;
          overflow: hidden;
          transition: visibility .5s ease-in-out;
          position: relative;


          &:hover {
            img {
              transform: scale(1.5);
            }

            .img-downloader {
              transition: .5s ease-in-out;
              background-color: rgba(40, 44, 52, 0.5);
              opacity: 1;
            }
          }

          > img {
            height: 90%;
            width: 90%;
            object-fit: contain;
            transition: .5s ease-in-out;

            @include is_moblie {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .img-downloader {
            opacity: 0;

            height: 100%;
            width: 100%;
            position: absolute;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            color: white;
            font-weight: 500;

            @include is_moblie{
              font-size: 0.7rem;
            }

            img {
              margin-top: 20px;
              height: 30px;
              color: white;
              @include is_moblie{
                transform: scale(1);
              }
            }
          }

        }

      }
    }
  }

}