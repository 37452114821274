@import "src/App";

.build-page {
  background-color: var(--yellow);
  height: 100vh;
  display: grid;
  place-items: center;
  color: white;

  .top {
    display: flex;
    width: 99vw;
    justify-content: space-around;

    img {
      object-fit: scale-down;
    }


    .logo {
      @include is_moblie {
        width: 30vw;
      }
    }

    .confetti {
      width: 30vw;
      @include is_moblie {
        width: 20vw;
      }
    }

  }

  a {
    color: #0F3768;
    text-decoration: none;
  }


  p {
    text-align: center;
    font-size: 20px;
  }

  .MuiButtonBase-root {
    color: white;
    font-family: Poppins, serif;
    font-weight: 600;
    font-size: 20px;
  }


}