@import "../../../../../App";


.unidade-container {
  width: 100%;

  .teste {

    @include is_moblie {
      width: 100vw;
      height: resize(210.71px);
      margin-left: calc(100vw - 128.6%);
    }

    .swiper-container {
      position: relative;

      img {
        width: 100%;
        height: resize(556);
        object-fit: cover;

        @include is_moblie {
          width: 100vw;
          height: resize(210.71px);
        }

      }
    }
  }


  .description {
    margin-top: 20px;
    font-family: Poppins, serif;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include is_moblie {
      justify-content: center;
    }


    .right {
      place-self: self-start;

      h3 {
        margin: 0;
        font-weight: 600;
        font-size: 2.59rem;

        span {
          color: #F18E00;
          font-size: 1.48rem;
        }

        @include is_moblie {
          span {
            font-size: 0.6972rem;
          }
          font-size: 1.22rem;
          //margin-bottom: 20px;
        }

      }

      p {
        font-size: 1rem;
        font-weight: 600;
        width: 110%;
        margin: 0 0 1vw;
        @include is_moblie {
          font-size: 0.49rem;
          width: 100%;
        }

        span {
          color: #F18E00;
        }
      }

    }


    .left {
      @include is_moblie {
        margin-top: 20px;
        align-items: center;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: flex-end;
      align-items: flex-end;


      img {
        height: resize(50.57);
        @include is_moblie {
          height: resize(34.91);
        }
        margin-bottom: 30px;
      }

    }


  }


}

