@import "src/App";

.button-igl {
  width: auto;
  height: fit-content;
  margin: 10px 0;
  padding: resize(11) resize(42);
  @include is_moblie {
    padding: resize(7.59414) resize(28.9958);
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;


  border: none;
  background: #F18E00;

  transition: 500ms;

  cursor: pointer;

  &:hover {
    transition: 500ms;
    background: #a86500;
  }

  p {
    font-family: Poppins, serif;
    font-size: 1.08rem;
    font-weight: 600;
    margin: 0;
    color: white;
    @include is_moblie {
      font-size: 0.7456rem;
    }
  }
}