@import "src/App";

.drawer{
  padding: 30px;

  .header{
    display: flex;
    height: 80px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
    img{
      height: 100%;
    }
    i{
      color: var(--dark-blue);
    }
  }

  //Items laterais
  .css-bshv44-MuiButtonBase-root-MuiListItem-root{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &:not(&:last-child){
      margin-bottom: 30px;
    }

    a{
      text-decoration: unset;
    }

    //Text inside ListItemText
    .css-10hburv-MuiTypography-root{
      color: var(--dark-blue);
      font-family: "Poppins", serif;
      font-weight: 500;
      font-size: 0.68rem;
    }
  }


}