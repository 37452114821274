@import "src/App";


.video-container {
  @include main-section;
  display: flex;
  flex-direction: column;
  height: resize(1157);


  @include is_moblie {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    height: resize(581);
  }

  h2 {
    @include title-section;
    margin: 1.8vw 0 resize(30);
    @include is_moblie {
      margin: resize_height(45) 0 resize(15);
    }
  }


  p {
    font-family: Poppins, serif;
    font-weight: 600;
    text-align: start;
    place-content: start;


    span {
      color: #F18E00;
    }

    @include is_moblie {
      text-align: center;
    }


  }

  .row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    .button-igl {
      margin: 15px 0;
      @include is_moblie {
        align-self: center;
      }

    }

    .subtitle {
      opacity: 0;
      font-size: 1.1rem;


      @include is_moblie {
        padding-top: 25px;
        font-size: 0.657rem;
        text-align: left;
      }

    }

    @include is_moblie {
      flex-direction: column;
    }
  }


  .text-focus-in {
    -webkit-animation: text-focus-in 600ms cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: text-focus-in 600ms cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }


  iframe {
    aspect-ratio: 16/9;
    height: resize(654.66);
    @include is_moblie {
      width: 100vw;
      margin-left: calc(100vw - 128.6%);
      height: resize-height(210.71);
      padding-left: 0;
    }

  }


}

