@import "src/App";

.header-class {
  //background-color: #f4f4f4;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr 1fr 2fr;
  place-items: center;
  left: 0;
  //border-radius: 0 0 20px 20px;
  padding: 5px 0;
  //height: 60px;
  //max-width: 1920px;
  z-index: 1000;
  transition: 500ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;

  @include is_moblie {
    padding: 5px 0;
    grid-template-columns: 1fr 2fr;
  }

  a {
    color: #0F3768;
    text-decoration: none;
  }

  p {
    font-weight: 500;
    font-size: 0.8rem;
    font-family: Poppins, serif;
    @include is_moblie {
      font-size: 0.75rem;
    }
  }

  img {
    height: resize(50)
  }

  .MuiFab-label {
    color: white;
    font-size: 0.75rem;
    @include is_moblie {
      font-size: 0.75rem;
    }

  }

  .MuiFab-root {
    width: 63%;
    height: resize(55);
    box-shadow: 0 3px 5px -1px rgba(243, 162, 64, 0.2), 0px 6px 10px 0px rgba(212, 136, 32, 0.14), 0px 1px 18px 0px rgba(239, 140, 9, 0.12);
    @include is_moblie {
      width: 80%;
    }

  }

}