@import "src/App";


.forms-container {
  @include main-section;
  height: resize(959);
  @include is_moblie {
    height: resize(740);
  }


  h2 {
    @include title-section;
    margin-top: 0;
  }


  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, auto);
    gap: 15px 80px;
    grid-auto-flow: row;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    place-items: flex-start;
    grid-template-areas:
    "nome-resp nome-aluno"
    "mail drop"
    "tel ."
    "btn-igl btn-igl";
    @include is_moblie {
      display: flex;
      flex-direction: column;
    }

    .center {
      display: flex;
      place-items: center;
    }

    .row {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      @include is_moblie {
        grid-template-columns: 1fr;
      }
    }


    label {
      width: 100%;
      font-size: 0.9rem;
      font-weight: 600;

      @include is_moblie {
        font-size: 0.5896rem;
        font-weight: 500;
      }


      input {
        width: 98%; //Descontando o padding
        height: resize(54);
        background-color: #F4F4F4;
        border: 1px solid #999999;
        font-size: 0.7rem;
        font-family: Poppins, serif;
        padding-left: 10px;

        &:hover, &:focus-visible {
          border: 1px solid var(--orange);
          outline: 2px solid #F18E00;
        }

        @include is_moblie {
          width: 98%;
          height: resize(38.66);
        }

      }

      .error {
        font-size: 0.5rem;
        padding: 0;
        margin: 0;
        color: indianred;
      }

      p {
        margin: 0 0 10px;
        padding: 0;
      }

    }


    .titles {
      font-family: Poppins, serif;
      font-size: 1.3rem;
      color: #0F3768;
      @include is_moblie {
        font-size: 1.2rem;
      }
    }


    .MuiFormControl-root {
      align-self: end;
      grid-area: drop;
      width: 100%;
      height: resize(54);

      @include is_moblie {
        height: resize(38.66);
      }


      .MuiInputBase-root {
        background: rgba(244, 244, 244, 1);
        border: 1px #999999;
        border-radius: 0;
        height: resize(54);

        @include is_moblie {
          height: resize(38.66);
        }


        .fas {
          color: var(--orange);
          margin-right: 15px;
        }
      }

      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border: 1px solid var(--orange);

      }

      .MuiOutlinedInput-input {
        padding: 0;
        background: rgba(244, 244, 244, 1);
        font-size: 0.7rem;
        padding-left: 6px;
      }

    }

  }

  .button-igl {
    grid-area: btn-igl;
    place-self: center;
    margin-top: 30px;

    i {
      margin-right: 20px;
      color: white;
      font-size: 1.3rem;
    }

    @include is_moblie {
      grid-area: unset;
    }
  }

  .aluno-controller {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    justify-self: flex-end;
    transition: 500ms;

    i {
      font-size: 0.8rem;
      padding: 0 10px;
      cursor: pointer;
      transition: ease 500ms;
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      p{
        margin: 0 15px 0 15px;
        @include is_moblie{
          font-size: 0.7rem;
        }
      }

      i:nth-child(1) {
        }
    }

    .button-igl {
      height: resize(50);
      width: resize(250);
      background-color: var(--green-water);
      margin: 0 0 0 resize(20);
      @include is_moblie{
        height: resize(40);
        width: resize(200);
      }

      p {
        font-size: 0.6rem;
        @include is_moblie{
          font-size: 0.5rem;
        }
      }
    }

    .button-igl + i{
      color: #F18E00;
    }

  }
}


