@import "src/App";

.footer-container {
  width: 100%;
  background-color: var(--dark-blue);
  display: grid;
  place-items: center;
  padding-bottom: 20px;

  .content {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;

    @include is_moblie {
      justify-content: left;
      flex-direction: column;
      align-items: flex-start;
    }

    img {
      object-fit: contain;
      height: resize(26);
      align-self: center;
    }

    .logo {
      height: resize(92.38);
    }


    .decription-div {
      display: flex;
      flex-direction: column;

      .item-div {
        display: flex;

        a {
          color: white;
          text-decoration: none;
        }

        p {
          margin-left: 10px;
          color: white;
          font-size: 0.6666667rem;
          @include is_moblie {
            font-size: 0.5952rem;
          }
        }
      }

    }

  }

  .logo {
    @include is_moblie {
      margin-top: resize(40);
      height: resize(56.51);
    }
  }

  .wave-bg {
    background-color: transparent;
    width: 100%;
    @include is_moblie {
      background-color: white;
    }

    .wave {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      overflow: hidden;
      line-height: 0;
      z-index: 10;
      background-color: white;
      transform: rotate(0deg);
      @include is_moblie {
        background-color: transparent;
      }
    }

    .wave svg {
      position: relative;
      display: block;
      width: calc(115% + 1.3px);
      height: 59px;
    }

    .wave .shape-fill {
      fill: var(--dark-blue);
    }
  }
}